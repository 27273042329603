.icn-btn-top:hover{
    background-color: #212730;
    outline: #4a4a4a solid 1px;
    box-sizing: border-box
  }
  
  .icn-btn-top{
    width: 42px;
    height: 42px;
    background-color: #161b22;
    border: none;
    font-size: 22px;
    text-align: center;
    margin: 0px 0px 0px 10px;
    border-radius: 8px;
    color:rgb(198, 198, 198);
    padding: 6px;
    box-sizing: border-box;
  }