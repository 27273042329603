.foldable-panel {
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: rgb(60, 60, 60);
    overflow: hidden;
  }
  
  .title-bar {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-text {
    margin: 0;
  }
  
  .panel-content-wrapper {
    overflow: scroll;
    transition: height 0.3s ease;
    height: 0;
    max-height: 200px;
  }
  
  .panel-content {
    padding: 10px;
    
  }